@import "~mapbox-gl/dist/mapbox-gl.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    background-color: #0e2d33;
  }
}

@layer components {
  .b-shadow {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  }

  nav.h-ps {
    box-shadow: 0 2px 4px rgb(0 0 0 / 30%);
  }

  .scroll-hide .main-logo {
    display: none;
  }

  /* .scroll-hide .lang-selector{
    display: none;
  } */

  /* Nav Links */
  .nav-link {
    @apply text-base font-semibold leading-4 tracking-[1px] py-1.5 lg:py-0;
  }

  /* Buttons */
  .btn {
    @apply rounded-lg p-4 font-titillium font-semibold whitespace-nowrap text-base text-center text-[#faf8f6] uppercase;
  }

  .btn.btn-primary {
    @apply bg-primary;
  }

  .btn.btn-secondary {
    @apply border-2 border-primary text-primary;
  }

  .btn.btn-success {
    @apply bg-success text-softChiffon;
  }

  .btn-outline-link {
    @apply px-6 border-2 rounded-lg uppercase tracking-[2px] border-link transition-all hover:bg-link hover:text-white;
  }

  .btn-label {
    @apply flex justify-center items-center font-semibold leading-[18px] tracking-[2px] uppercase text-center;
  }

  .bottom-bar-btn {
    @apply p-4 w-1/2 flex gap-2 justify-center items-center font-titillium font-semibold whitespace-nowrap text-base text-center text-[#faf8f6] uppercase;
  }

  /* Dropdown */

  /* .dropdown {
    @apply relative;
  }
  .dropdown-toggle {
    @apply whitespace-nowrap cursor-pointer;
  }
  .dropdown-menu {
    @apply absolute z-[10] hidden m-0 p-[5px] text-[16px] text-black text-left bg-clip-padding bg-white shadow-md rounded-sm;
  }
  .dropdown-menu.show {
    @apply block left-0;
  }
  .dropdown-item {
    @apply block w-full p-3 text-black whitespace-nowrap bg-transparent border-0;
  } */

  /* Header Mob Collapse */
  .collapse {
    @apply hidden;
  }

  .navbar-collapse {
    @apply lg:flex items-center px-8 py-8 lg:p-0 bg-[#0E2D33] lg:bg-transparent;
    /*flex-basis: 100%;*/
    /*flex-grow: 1;*/
  }

  .navbar-collapse .nav-link {
    @apply text-white;
  }

  /* .lang-selector {
    @apply md:absolute xl:relative top-[40px] xl:top-0 lg:right-8 xl:right-0;
  } */
  /* .lang-selector .dropdown-item.active {
    @apply bg-link text-white;
  } */
  /* .navbar-collapse .lang-selector a {
    @apply text-link;
  } */

  /* .language-selector-modal {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  max-width: 250px;
  margin: 0;
  border: none;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
} */

  .menu-bar {
    @apply flex relative transition-all;
  }

  .img-object-contain img {
    object-fit: contain;
  }

  .img-object-position-left img {
    object-position: left;
  }

  .mob-menu {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    transform: translateX(120%);
    transition: all 0.2s linear;
  }

  .mob-menu.added {
    transform: translateX(0);
  }

  .mini-logo a {
    width: 40px;
    height: 40px;
  }

  .mini-logo img {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
  }

  .fromStickyMenu {
    @apply z-[10] fixed top-[50px];
  }

  /* Typography */
  .details {
    @apply font-normal text-sm leading-4 text-white;
  }

  .heading-1 {
    @apply text-primary uppercase font-optiJake-regular tracking-[1px] text-[52px] sm:text-[66px] leading-[56px] sm:leading-[70px];
  }

  .heading-2 {
    @apply text-primary font-optiJake-regular uppercase text-[41px] sm:text-[52px] leading-[44px] sm:leading-[56px] tracking-[0.7] sm:tracking-normal font-medium;
  }

  .heading-4 {
    @apply font-optiJake-medium font-normal text-lg leading-5 tracking-[1px] text-center uppercase;
  }

  .structured-text p {
    @apply font-normal text-base leading-[18px];
  }

  /* Owl Customization */
  .owl-flamel .owl-prev,
  .owl-flamel .owl-next {
    @apply absolute bottom-8 z-10 w-9 h-9 b-shadow !bg-black !rounded-full [&>span]:text-[17px];
  }

  /* .owl-flamel .owl-prev span,
  .owl-flamel .owl-next span {
    @apply text-[40px] text-[#FFF];
  } */

  .owl-flamel .owl-prev {
    @apply -left-[100px];
  }

  .owl-flamel .owl-next {
    @apply -right-[100px];
  }
}

/* Hide Arrow */

@media screen and (min-width: 767px) {
  .hide-prev-arrow .owl-nav .owl-prev {
    display: none !important;
  }

  .hide-next-arrow .owl-nav .owl-next {
    display: none !important;
  }
}

Owl arrow outside .owl-carousel.owl-arrow-inside {
  position: unset;
}

.owl-carousel.owl-arrow-inside .owl-nav [class*="owl-"] {
  margin: 0;
}

.owl-carousel.owl-arrow-inside .owl-nav .owl-prev {
  left: 50%;
  transform: translateX(-120%);
}

.owl-carousel.owl-arrow-inside .owl-nav .owl-next {
  right: 50%;
  transform: translateX(120%);
}

.event-slider .owl-item.cloned {
  height: 400px;
}

body {
  overflow-x: hidden;
}

/* Structure format */

.structure-format ol {
  list-style: auto;
  margin: 20px 0 20px 20px;
  display: block;
}

.structure-format ul {
  list-style: disc;
  margin: 20px 0 20px 20px;
  display: block;
}

.structure-format ol li,
.structure-format ul li {
  margin-bottom: 10px;
}

.structure-format strong {
  font-weight: 600;
}

.structure-format blockquote {
  padding: 20px;
  background-color: rgb(250, 250, 250);
  border-left: 5px solid rgb(87, 87, 87);
  margin: 20px 0;
}

.structure-format p {
  @apply text-sm;
}

.structure-format pre {
  margin: 20px 0;
  padding: 20px;
  background-color: rgb(233 246 255 / 67%);
}

.structure-format hr {
  margin-right: auto;
  margin-left: auto;
  width: 140px;
  color: white;
}

.structure-para p {
  @apply font-light text-base my-4 xl:w-full md:mx-auto first:mt-0;
}

.structure-para.social-wifi p {
  @apply mb-0 text-base font-semibold text-primary leading-[22px];
}

.structure-menu-para p {
  @apply text-[14px] font-light;
}

.structure-menu-para-bold p {
  @apply text-[14px] font-bold font-sans;
}

.structure-menu-para-normal p {
  @apply text-[14px] font-normal leading-[18px] font-sans;
}

.structure-para-normal p {
  @apply text-base;
}

.structure-spacing p {
  @apply pt-1 pb-2 block;
}

.structure-content p {
  @apply text-2xl font-semibold tracking-[0.7px] leading-7;
}

.structure-title p {
  @apply text-[20px] md:text-[28px] font-normal;
}

.structure-data p {
  @apply text-[20px] md:text-[26px] font-normal leading-[1.35];
}

.structure-desc p {
  @apply font-light text-base;
}

.structure-details p {
  @apply font-light text-base leading-6;
}

.structure-link a {
  color: #1b6177;
  text-decoration: underline;
}

.footer-contact-links a {
  @apply text-white font-bold text-[14px];
}

p:empty {
  @apply pb-4;
}

.map-icon-box {
  width: 30px;
  height: 30px;
  overflow: visible;
  transition: all 0.5s ease-in-out;
}

.footer-email a {
  @apply text-[14px] text-white font-bold;
}

.footer-email p {
  @apply text-[14px] text-white font-bold;
}

.font-small p {
  font-size: 14px;
}

.object-set {
  height: 100%;
}

.object-set img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 767px) {
  .inner-mobile-carousel-box.object-set {
    position: absolute !important;
  }
}

/* .bg-left-new{
    background-image: url(https://www.datocms-assets.com/60644/1642687310-7-slide-restaurantfood11994.jpg?ar=1x1&auto=compress%2Cformat&fit=crop);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
   
} */
/* .bg-left-new::before{
    position: absolute;
    background: rgba(0,0,0,0.7);
    height: 100%;
    width: 100%;
    content: "";
} */

.form-main {
  margin: 0px -8px;
}

.error-message {
  font-size: 14px;
  margin-top: 5px;
}

.error-message span {
  color: #c5003e;
}

.error-control {
  border-bottom: 1px solid #c5003e !important;
}

.input-control {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #d7d7d7;
  color: #7b878d;
  width: 100%;
}

.input-control:focus {
  border: none;
  border-bottom: 1px solid #d7d7d7;
  box-shadow: none;
  outline: none;
}

.radioButton-main {
  appearance: none;
  -webkit-appearance: none;
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 0.5rem;
  border: 0.2rem solid #fff;
  background-color: #e9e7e7;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #e9e7e7;
}

.radioButton-main:focus-visible {
  outline-offset: 0;
}

.radioButton-main:hover {
  border-width: 0;
  cursor: pointer;
}

.radioButton-main:checked {
  background-color: #cdb8a5;
  border-width: 0.2rem;
}

.checkbox-main input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox-main label {
  position: relative;
  cursor: pointer;
  padding-left: 35px;
  display: inline-block;
}

.checkbox-main label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #eeebec;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05); */
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 15px;
  left: 0px;
  position: absolute;
}

.checkbox-main input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 8px;
  width: 6px;
  height: 14px;
  border: solid #9a9a9a;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.left-overlay::before {
  background: rgb(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  content: "";
  position: absolute;
  z-index: -9;
}

.overlay-bg::before {
  background: rgb(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  content: "";
  position: absolute;
  z-index: 99;
}

.overlay-bg.dark::before {
  background: rgb(0, 0, 0, 0.5);
}

.custom-z-index {
  z-index: 9999;
}

input[type="date"]:before {
  content: attr(placeholder) !important;
  color: #aaa;
  margin-right: 0.5em;
}

input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "" !important;
}

.sm-logo {
  left: -23px !important;
  position: relative;
}

@media (max-width: 767px) {
  .sm-logo {
    left: 0px !important;
  }
}

.one-slide .owl-stage {
  margin: 0 auto;
  display: block;
}

.instagram-abstract {
  align-items: unset;
}

.a-center {
  align-items: center !important;
}

.bg-custom {
  background-color: transparent !important;
}

/* bug in swiper */
@media (max-width: 1023px) {
  .instagram-abstract .items-center {
    align-items: unset;
  }
}

.swiper-main-custom .swiper > .swiper-wrapper {
  margin-bottom: 15px;
}

.swiper-main-custom .custom-wrapper.swiper > .swiper-wrapper {
  margin-bottom: 50px;
}

.swiper-pagination-bullet-active {
  background-color: #e35219 !important;
}

.swiper-main-custom .swiper > .swiper-wrapper .swiper-wrapper {
  margin-bottom: 0px;
}

.futureFarmersPage {
  padding-top: 27px 0 !important;
}

.no-scroll {
  overflow: hidden;
}

.reservation-widget {
  position: relative;
  /* width: 100%;
  padding-top: 100%; */
}

.reservation-widget > iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transform-origin: left top;
}

/* @media (max-width: 376px) {
  .reservation-widget > iframe {
    width: 168%;
    height: 168%;
    transform: scale(0.7);
  }
} */

/* privacy policy page styles */
.privacy-policy-cookie-script-table {
  overflow-x: auto;
  padding: 1rem 0;
}

.privacy-policy-cookie-script-table table {
  margin: 1rem 0;
}

.privacy-policy-cookie-script-table table tbody tr td {
  font-family: "FuturaLT-regular";
  font-size: 15px;
  line-height: 1.5rem;
}

.privacy-policy-cookie-script-table table tbody tr td a {
  text-decoration: underline;
}

#privacy-policy-page p {
  margin: 0.75rem 0;
}

#privacy-policy-page h3 {
  margin: 1rem 0;
}

#privacy-policy-page :is(h1, h2, h3, h4, h5, h6) {
  margin: 1rem 0;
}

#privacy-policy-page :is(h1) {
  margin: 1rem 0;
  font-size: 2rem;
}

#privacy-policy-page :is(h3, h4) {
  margin: 1rem 0;
  font-size: 1.5rem;
}

#privacy-policy-page ul {
  list-style: disc;
  padding-left: 1.5rem;
  margin: 1rem 0;
}

/* To account for the font change, removed when the font is changed */
/* .btn {
  padding-top: 1.5rem;
  padding-bottom: 0.75rem;
} */

@media (min-width: 426px) {
  .owl-carousel .owl-nav button.owl-prev > span,
  .owl-carousel .owl-nav button.owl-next > span{
  @apply hidden
}
  .owl-carousel .owl-nav button.owl-prev{
    background-image: url('../src/assets/img/left.svg') !important;
  }

  .owl-carousel .owl-nav button.owl-next{
    background-image: url('../src/assets/img/right.svg') !important;
  }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next{
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 0.6rem !important;
    width: 56px !important;
    height: 56px !important;
  }
}